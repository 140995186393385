html,
body {
  line-height: 1.15;
  min-height: -webkit-fill-available;
}

#__next {
  position: relative;
  width: 100%;
  min-height: -webkit-fill-available;
  max-height: 100vh;
}

body {
  font-family: "Inter", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Deezer Product", Arial, sans-serif;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

/* 
 * Utility class to hide content visually while keeping it screen reader-accessible.
 * Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html 
 */
.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
  }
}
